import React, { useState } from 'react'
import { Link, FormattedMessage } from 'gatsby-plugin-intl'
// import ClassNames from 'classnames';
import componentStyles from './ToolTip.module.scss'
import { FaQuestionCircle } from 'react-icons/fa'

const ToolTip = ({ link, internal, definition, ...props }) => {
  const [isVisible, setVisibility] = useState(false)

  return (
    <span className={componentStyles.toolTip}>
      {link && internal && (
        <Link
          to={link}
          className={componentStyles.text}
          onMouseEnter={() => setVisibility(true)}
          onMouseLeave={() => setVisibility(false)}
        >
          {props.children}
          <span className={componentStyles.icon} aria-hidden='true'>
            <FaQuestionCircle />
          </span>
        </Link>
      )}
      {link && !internal && (
        <a
          href={link}
          className={componentStyles.text}
          onMouseEnter={() => setVisibility(true)}
          onMouseLeave={() => setVisibility(false)}
        >
          {props.children}
          <span className={componentStyles.icon} aria-hidden='true'>
            <FaQuestionCircle />
          </span>
        </a>
      )}
      {/* {(!link && !internal) &&
                <a href="" className={componentStyles.text} onMouseEnter={() => setVisibility(true)}
                onMouseLeave={() => setVisibility(false)}>
                    {props.children}
                    <span className={componentStyles.icon} aria-hidden="true"><FaQuestionCircle /></span>
                </a>
            } */}
      <div
        className={componentStyles.definition}
        aria-hidden={isVisible}
        role='tooltip'
      >
        <p>{definition}</p>

        {link && (
          <span className='footnote'>
            <FormattedMessage id='nav.toolTipLink' />
          </span>
        )}
      </div>
    </span>
  )
}

export default ToolTip
