import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../../components/Layouts/layout'
import SEO from '../../../components/seo'
import SubPageNav from '../../../components/SubPageNav/SubPageNav'
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav'
import ToolTip from '../../../components/ToolTip/ToolTip'

const BuilderAfterSubmittedPage = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      builderImg: file(relativePath: { eq: "builder_hammering.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout
      view='builder'
      breadcrumb={{
        text: <FormattedMessage id='builderNav.dashboard' />,
        path: '/builder-vendor/dashboard/'
      }}
      header={
        <>
          <FormattedMessage id='builderNav.becomeA' />{' '}
          <span style={{ fontWeight: `bold` }}>
            <FormattedMessage id='builderNav.becomeB' />
          </span>
        </>
      }
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'builderNav.step4' })}
      />
      <Container>
        <Row>
          <Col>
            <SubPageNav
              links={[
                {
                  text: `${intl.formatMessage({ id: 'builderNav.step1' })}?`,
                  path:
                    '/builder-vendor/become-a-licensed-builder/do-i-need-a-licence'
                },
                {
                  text: <FormattedMessage id='builderNav.step2' />,
                  path:
                    '/builder-vendor/become-a-licensed-builder/before-you-apply'
                },
                {
                  text: <FormattedMessage id='builderNav.step3' />,
                  path:
                    '/builder-vendor/become-a-licensed-builder/applying-for-a-licence/'
                },
                {
                  text: <FormattedMessage id='builderNav.step4' />,
                  path:
                    '/builder-vendor/become-a-licensed-builder/after-your-application-is-submitted/',
                  active: true
                },
                {
                  text: <FormattedMessage id='builderNav.step5' />,
                  path:
                    '/builder-vendor/become-a-licensed-builder/after-licensing'
                }
              ]}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={12}>
            <h2>
              <FormattedMessage id='becomeBuilder4.headerA' />
            </h2>
            <p>
              <FormattedMessage id='becomeBuilder4.contentAi' />
            </p>
            <p>
              <FormattedMessage id='becomeBuilder4.contentAii' />
            </p>
            <p>
              <FormattedMessage id='becomeBuilder4.contentAiii' />
            </p>

            <ol className='largeNums'>
              <li>
                <div>
                  <h3>
                    <FormattedMessage id='becomeBuilder4.list01A_B' />
                  </h3>
                  <FormattedMessage id='becomeBuilder4.list01Ai' />{' '}
                  <a href='../../../1. HCRA Advisory - Guide to Good Conduct v1.7 2021-07-09.pdf'>
                    <FormattedMessage id='becomeBuilder4.list01Aii' />
                  </a>
                  .
                </div>
              </li>
              <li>
                <div>
                  <h3>
                    <FormattedMessage id='becomeBuilder4.list01B_B' />
                  </h3>
                  <FormattedMessage id='becomeBuilder4.list01B' />
                </div>
              </li>
              <li>
                <div>
                  <h3>
                    <FormattedMessage id='becomeBuilder4.list01C_B' />
                  </h3>
                  <FormattedMessage id='becomeBuilder4.list01C' />
                </div>
              </li>
              <li>
                <div>
                  <h3>
                    <FormattedMessage id='becomeBuilder4.list01D_B' />
                  </h3>
                  <FormattedMessage id='becomeBuilder4.list01D' />
                </div>
              </li>
            </ol>
            <p>
              <FormattedMessage id='becomeBuilder4.contentBi' />{' '}
              <ToolTip
                link='/glossary/#principal'
                internal
                definition={
                  <FormattedMessage id='glossaryTerms.principalDef' />
                }
              >
                <FormattedMessage id='glossaryTerms.principalTermB' />
              </ToolTip>
              ,{' '}
              <ToolTip
                link='/glossary/#director'
                internal
                definition={<FormattedMessage id='glossaryTerms.dirDef' />}
              >
                <FormattedMessage id='glossaryTerms.dirTermC' />
              </ToolTip>
              ,{' '}
              <ToolTip
                link='/glossary/#officer'
                internal
                definition={<FormattedMessage id='glossaryTerms.offDef' />}
              >
                <FormattedMessage id='glossaryTerms.offTermC' />
              </ToolTip>
              ,{' '}
              <ToolTip
                link='/glossary/#partner'
                internal
                definition={<FormattedMessage id='glossaryTerms.partnerDef' />}
              >
                <FormattedMessage id='glossaryTerms.partnerTermB' />
              </ToolTip>
              <FormattedMessage id='becomeBuilder4.contentBii' />{' '}
              <ToolTip
                link='/glossary/#interestedPersons'
                internal
                definition={<FormattedMessage id='glossaryTerms.ipDef2' />}
              >
                <FormattedMessage id='glossaryTerms.ipTermB' />
              </ToolTip>
              .
            </p>
          </Col>
        </Row>

        <Row className='pageRow' align='center'>
          <Col>
            <h2>
              <FormattedMessage id='becomeBuilder4.headerB' />
            </h2>
            <p>
              <FormattedMessage id='becomeBuilder4.contentCi' />
            </p>
            <ul>
              <li>
                <FormattedMessage id='becomeBuilder4.list02A' />
              </li>
              <li>
                <FormattedMessage id='becomeBuilder4.list02B' />
              </li>
              <li>
                <FormattedMessage id='becomeBuilder4.list02C' />
              </li>
            </ul>
            <p>
              <FormattedMessage id='becomeBuilder4.contentCii' />
            </p>
          </Col>

          <Col lg={6} md={12}>
            <Img fluid={data.builderImg.childImageSharp.fluid} alt='' />
          </Col>
        </Row>

        <Row className='pageRow'>
          <Col>
            <h2>
              <FormattedMessage id='becomeBuilder4.headerC' />
            </h2>
            <p>
              <FormattedMessage id='becomeBuilder4.contentDi' />
            </p>
            <p>
              <FormattedMessage id='becomeBuilder4.contentDii' />
            </p>
          </Col>
        </Row>

        <Row className='pageRow'>
          <Col>
            <h2>
              <FormattedMessage id='becomeBuilder4.headerD' />
            </h2>
            <p>
              <FormattedMessage id='becomeBuilder4.contentEi' />
            </p>
            <ol className='largeNums'>
              <li>
                <div>
                  <b>
                    <FormattedMessage id='becomeBuilder4.list03A_B' />
                  </b>
                  <br />
                  <FormattedMessage id='becomeBuilder4.list03A' />
                </div>
              </li>

              <li>
                <div>
                  <b>
                    <FormattedMessage id='becomeBuilder4.list03B_B' />
                  </b>
                  <br />
                  <FormattedMessage id='becomeBuilder4.list03B' />
                </div>
              </li>

              <li>
                <div>
                  <b>
                    <FormattedMessage id='becomeBuilder4.list03C_B' />
                  </b>
                  <br />
                  <FormattedMessage id='becomeBuilder4.list03C' />
                </div>
              </li>

              <li>
                <div>
                  <b>
                    <FormattedMessage id='becomeBuilder4.list03D_B' />
                  </b>
                  <br />
                  <FormattedMessage id='becomeBuilder4.list03Di' />
                  <br />
                  <br />
                  <FormattedMessage id='becomeBuilder4.list03Dii' />{' '}
                  <b>
                    <FormattedMessage id='becomeBuilder4.list03Diii' />
                  </b>{' '}
                  <FormattedMessage id='becomeBuilder4.list03Div' />
                </div>
              </li>
            </ol>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col md={12}>
            <SubPageBottomNav
              prevLink={{
                text: <FormattedMessage id='builderNav.step3' />,
                path:
                  '/builder-vendor/become-a-licensed-builder/applying-for-a-licence/'
              }}
              nextLink={{
                text: <FormattedMessage id='builderNav.step5' />,
                path:
                  '/builder-vendor/become-a-licensed-builder/after-licensing'
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BuilderAfterSubmittedPage
